<template>
  <div style="position: fixed;top: 50%;left: 40%;margin-top: -50px;margin-left: -100px;">
    <b-button :variant="variant" disabled class="mr-1">
      <b-spinner v-if="spin" small/>
      {{ mesaj }}
    </b-button>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {set, deleteCokie} from '../Services/CookieJob'
import store from '@/store'
import {getItems} from "@/views/Services/CartUpdate";

export default {
  name: 'Login',
  components: {ToastificationContent},
  data() {
    return {
      mesaj: "Sisteme girişiniz kontrol ediliyor",
      spin: true,
      variant: "primary"
    }
  },
  computed: {
    veri: {
      get() {
        let vv = store.getters['loginStoreModule/getUserBilgileri']
        if (vv.guid) {
          this.tokenGeldi(vv.guid);
        }
        return vv;
      },
    }
  },
  methods: {
    async basarili(data) {
      this.mesaj = data["customer"] + ' Hoşgeldiniz; Yönlendiriliyorsunuz lütfen bekleyin',
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Bilgi',
              icon: 'BellIcon',
              text: data["customer"] + ' Hoşgeldiniz; Yönlendiriliyorsunuz lütfen bekleyin',
              variant: 'success'
            },
          }, {
            timeout: 3000
          })
      if (data != false) {
        if (this.$route.params.id) {
          set("guid", this.$route.params.id, 1);
          store.commit('loginStoreModule/SET_USER_BILGILERI', data)
          await getItems(this.$route.params.id);
        }
        this.$router.push({name: 'dashboard'})

      } else this.basarisiz();

    },
    basarisiz() {
      this.spin = false;
      this.variant = "danger";
      this.mesaj = '⛔️ Hata ile karşılaşıldı; Lütfen teslimat sırasında gönderilen son link ile giriş yapınız.',
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata',
              icon: 'BellIcon',
              text: '⛔️ Hata ile karşılaşıldı; Lütfen teslimat sırasında gönderilen son link ile giriş yapınız.',
              variant: 'danger'
            },
          }, {
            timeout: 3000
          })
      deleteCokie();
    },
    tokenGeldi(val) {
      if (val) {
        this.$http.get("MusteriPortaliGuidCheckV1/" + val).then((i) => {
          if (i.data["status"] == true) this.basarili(i.data);
          if (i.data["status"] == false || i.data == false) this.basarisiz();
        });
      }
    }

  },
  mounted() {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'Bilgi',
        icon: 'BellIcon',
        text: 'Sisteme Girişiniz Yapılıyor',
        variant: 'info'
      },
    }, {
      timeout: 3000
    })
    if (this.$route.params.id) {
      this.$http.get("MusteriPortaliGuidCheckV1/" + this.$route.params.id).then((i) => {
        if (i.data["status"] == true) this.basarili(i.data);
        if (i.data["status"] == false || i.data == false) this.basarisiz();
      });
    } else if (this.veri.data) {
      this.$http.get("MusteriPortaliGuidCheckV1/" + this.veri.data.guid).then((i) => {
        if (i.data["status"] == true) this.basarili(i.data);
        if (i.data["status"] == false || i.data == false) this.basarisiz();
      });
    } else
      this.basarisiz();

  }
};

</script>
